import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie, getCookie, setCookie } from "cookies-next";

const data = getCookie("user")
const token = getCookie("access_token")

const intialValue = {
  token: token ? token : null,
  user: data ? JSON.parse(data) : null,
};

export const userLoginSlice = createSlice({
  name: "userToken",
  initialState: { value: intialValue },
  reducers: {
    setAccessToken: (state, action) => {
      state.value = {
        ...state.value.token,
        token: action.payload.access_token,
      };
      setCookie("access_token", action.payload.access_token, {
        expires: new Date(Date.now() + action.payload.expiresIn * 1000),
        path: "/",
      })

    },
    login: (state, action) => {
      const user = {
        "firstName": action.payload.user.firstName,
        "lastName": action.payload.user.lastName,
        "username": action.payload.user.username,
        "email": action.payload.user.email,
        "phoneNumber": action.payload.user.phoneNumber,
        "profileImage": action.payload.user.profileImage,
        "isAssessmentcompleted": action.payload.user.isAssessmentcompleted,
        "phoneNumberVerifiedAt": action.payload.user.phoneNumberVerifiedAt,
        "emailVerifiedAt": action.payload.user.emailVerifiedAt,
        "countryCode": action.payload.user.countryCode,
        "city": action.payload.user.city,
        "gems": action.payload.user.gems,
        "starLevel": action.payload.user.starLevel,
        "role": action.payload.user.role
      }
      state.value = {
        token: action.payload.access_token,
        user: user,
      };
      setCookie("access_token", action.payload.access_token, {
        expires: new Date(Date.now() + action.payload.expiresIn * 1000),
        path: "/",
      })
      setCookie("user", user, {
        expires: new Date(Date.now() + action.payload.expiresIn * 1000),
        path: "/",
      })

    },
    updateProfile: (state, action) => {
      const user = {
        "firstName": action.payload.user.firstName,
        "lastName": action.payload.user.lastName,
        "username": action.payload.user.username,
        "email": action.payload.user.email,
        "phoneNumber": action.payload.user.phoneNumber,
        "profileImage": action.payload.user.profileImage,
        "isAssessmentcompleted": action.payload.user.isAssessmentcompleted,
        "phoneNumberVerifiedAt": action.payload.user.phoneNumberVerifiedAt,
        "emailVerifiedAt": action.payload.user.emailVerifiedAt,
        "countryCode": action.payload.user.countryCode,
        "gems": action.payload.user.gems,
        "starLevel": action.payload.user.starLevel,
        "city": action.payload.user.city,
        "role": action.payload.user.role
      }
      state.value = {
        token: token,
        user: user,
      };
      setCookie("user", user, {
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        path: "/",
      })
    },
    setUser: (state, action) => {
      const user = {
        "firstName": action.payload.user.firstName,
        "lastName": action.payload.user.lastName,
        "username": action.payload.user.username,
        "email": action.payload.user.email,
        "phoneNumber": action.payload.user.phoneNumber,
        "profileImage": action.payload.user.profileImage,
        "isAssessmentcompleted": action.payload.user.isAssessmentcompleted,
        "phoneNumberVerifiedAt": action.payload.user.phoneNumberVerifiedAt,
        "emailVerifiedAt": action.payload.user.emailVerifiedAt,
        "countryCode": action.payload.user.countryCode,
        "city": action.payload.user.city,
        "gems": action.payload.user.gems,
        "starLevel": action.payload.user.starLevel,
        "role": action.payload.user.role
      }
      state.value = {
        ...state.value,
        user: user,
      };
      setCookie("user", user, {
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        path: "/",
      })
    },
    logout: (state, action) => {
      state.value = {
        token: null,
        user: null,
      };
      deleteCookie("access_token")
      deleteCookie("user")
      deleteCookie("progress_bar")
    }
  },
});

export const { login, logout, setUser, setAccessToken, updateProfile } = userLoginSlice.actions;
export default userLoginSlice.reducer;
